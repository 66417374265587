.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#profilelinks aaaa {
  background-color: lightgrey;
  width: 800px;
  border: 15px solid green;
  padding: 50px;
  margin: 20px;
  
}
.profilelinkss:hover {
  background-color: lightgrey;
}

.profilelinkss {
  text-align: center;
  
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 15px;
  background: #c7c5c4; /*73AD21*/ 
  padding: 20px;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
  height: auto;

}

a {
  text-decoration: none;
  color: black;
  
}
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }